import auth from "services/admin/auth";
import { PageLoading } from "@ant-design/pro-layout";
import { useRequest } from "ahooks";
import { message } from "antd";
import { createContext, FC, ReactNode, useContext, useReducer } from "react";
import { Action, DataType, ReducerType, Refrences, Type } from "./types";
import reference from "services/guest/reference";

const defaultRefrence: Refrences = {
  loading: true,
};

const defaultData: DataType = {
  authorized: false,
  settings: {
    layout: "side",
    navTheme: "dark",
    colorWeak: false,
    title: "VMS",
    fixedHeader: true,
    fixSiderbar: true,
    contentWidth: "Fluid",
    listen_socket: false,
  },
  refrences: { ...defaultRefrence },
  langs: [],
  notificationsCount: 0,
};

const reducer: ReducerType = (state, action) => {
  switch (action[0]) {
    case Action.SET_SETTINGS:
      return { ...state, settings: action[1] };
    case Action.SIGN_IN:
      return { ...state, auth: action[1], authorized: true };
    case Action.SET_REFRENCES:
      return { ...state, refrences: action[1] };
    case Action.INCREASE_NOTI_COUNT:
      return { ...state, notificationsCount: state.notificationsCount + 1 };
    case Action.SET_LANGS:
      return { ...state, langs: action[1] };
    case Action.SIGN_OUT:
      auth.remToken();
      return { ...state, auth: undefined, authorized: false };
    default:
      return state;
  }
};

const UserContext = createContext<Type>([
  defaultData,
  () => {
    // do nothing.
  },
  {
    info: () => {},
  },
]);

export const StoreProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [redux, setRedux] = useReducer(reducer, defaultData);
  const _info = useRequest(auth.info, {
    onSuccess: (data) => setRedux([Action.SIGN_IN, data]),
    // onError: (err) => message.error(err.message),
  });

  const _lang = useRequest(reference.listLang, {
    onSuccess: (data) => setRedux([Action.SET_LANGS, data || []]),
    onError: (err) => message.error(err.message),
  });

  return (
    <UserContext.Provider value={[redux, setRedux, { info: _info.refresh }]}>
      {_info.loading || _lang.loading ? <PageLoading /> : children}
    </UserContext.Provider>
  );
};

export const useStore: () => Type = () => useContext<Type>(UserContext);

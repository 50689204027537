import { lazy } from "react";
import { IRoute } from "./types";

const VeritechRequestDetail = lazy(() => import("pages/dashboard/finance/veritech_integration/view_request"));
const NewsComponent = lazy(() => import("pages/dashboard/application_management/news"));
const TransactionComponent = lazy(() => import("pages/dashboard/application_management/transaction"));
const PromoComponent = lazy(() => import("pages/dashboard/application_management/promo"));

const SurveyComponent = lazy(() => import("pages/dashboard/application_settings/survey"));
const TermOfService = lazy(() => import("pages/dashboard/application_settings/term_of_service"));
const CustomerProducts = lazy(() => import("pages/dashboard/application_management/product"));
const Customers = lazy(() => import("pages/dashboard/application_management/customer"));
const WheelComponent = lazy(() => import("pages/dashboard/application_settings/wheel"));
const NotFound = lazy(() => import("pages/exceptions/NotFound"));
const Staffs = lazy(() => import("pages/dashboard/user/staff"));
const Organizations = lazy(() => import("pages/dashboard/user/organization"));
const Permissions = lazy(() => import("pages/dashboard/rbac/permission"));
const Groups = lazy(() => import("pages/dashboard/rbac/group"));
const Roles = lazy(() => import("pages/dashboard/rbac/role"));
const Categories = lazy(() => import("pages/dashboard/menu/category"));
const Suppliers = lazy(() => import("pages/dashboard/menu/supplier"));
const Measurements = lazy(() => import("pages/dashboard/menu/measurement"));
const Products = lazy(() => import("pages/dashboard/menu/product"));
const ProductChangePrice = lazy(() => import("pages/dashboard/menu/product/change_price"));
const Locations = lazy(() => import("pages/dashboard/core/location"));
const Models = lazy(() => import("pages/dashboard/core/model"));
const Machines = lazy(() => import("pages/dashboard/core/machine"));
const KeyRegistration = lazy(() => import("pages/dashboard/core/key_registration"));
// const TechRequests = lazy(() => import("pages/dashboard/core/tech_request"));
const Trips = lazy(() => import("pages/dashboard/core/trip"));
const MachineSingle = lazy(() => import("pages/dashboard/core/machine/id"));
const AdvertisementSingle = lazy(() => import("pages/dashboard/marketing/advertisement/id"));

const Advertisements = lazy(() => import("pages/dashboard/marketing/advertisement"));
const Promos = lazy(() => import("pages/dashboard/marketing/promo"));
const MachineFeedback = lazy(() => import("pages/dashboard/feedback/machine_feedback"));
const CustomerFeedback = lazy(() => import("pages/dashboard/feedback/customer_feedback"));
const Dashboard = lazy(() => import("pages/dashboard"));
const InvoiceMonth = lazy(() => import("pages/dashboard/finance/invoice_month"));
const UpointMonth = lazy(() => import("pages/dashboard/finance/upoint_month"));
const Upoint = lazy(() => import("pages/dashboard/finance/upoint"));
const Invoices = lazy(() => import("pages/dashboard/finance/invoice"));
const VeritechReports = lazy(() => import("pages/dashboard/finance/veritech"));
const VeritechIntegration = lazy(() => import("pages/dashboard/finance/veritech_integration"));
const VeritechDateRequestDetail = lazy(() => import("pages/dashboard/finance/veritech_integration/view"));
const BankCheck = lazy(() => import("pages/dashboard/finance/bank_check"));
const StaffChanges = lazy(() => import("pages/dashboard/finance/staff_change"));
const StaffWarehouse = lazy(() => import("pages/dashboard/finance/staff_warehouse"));
const Inventory = lazy(() => import("pages/dashboard/finance/inventory"));
const TripReportByStaff = lazy(() => import("pages/dashboard/finance/trip_report_by_staff"));
const MachineFeedbackRef = lazy(() => import("pages/dashboard/feedback/machine_feedback_ref"));

const Langs = lazy(() => import("pages/dashboard/settings/lang"));
const MachineLock = lazy(() => import("pages/dashboard/settings/machine_lock"));
const Notifications = lazy(() => import("pages/dashboard/settings/notification"));

const SystemLog = lazy(() => import("pages/dashboard/monitor/system_log"));
const ActionLog = lazy(() => import("pages/dashboard/monitor/action_log"));
const MachineLog = lazy(() => import("pages/dashboard/monitor/machine_log"));
const PaymentTypes = lazy(() => import("pages/dashboard/settings/payment_type"));
const PaymentDiscount = lazy(() => import("pages/dashboard/settings/payment_discount"));
const PointManagement = lazy(() => import("pages/dashboard/application_management/point_management"));
const Coupon = lazy(() => import("pages/dashboard/application_management/coupon"));
const dashboardRoutes: IRoute[] = [
  {
    key: "/dashboard",
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    key: "/dashboard/settings/payment_type",
    path: "/dashboard/settings/payment_type",
    component: <PaymentTypes />,
  },
  {
    key: "/dashboard/settings/payment_discount",
    path: "/dashboard/settings/payment_discount",
    component: <PaymentDiscount />,
  },
  {
    key: "/dashboard/settings/machine_lock",
    path: "/dashboard/settings/machine_lock",
    component: <MachineLock />,
  },

  {
    key: "/dashboard/settings/notification",
    path: "/dashboard/settings/notification",
    component: <Notifications />,
  },
  {
    key: "/dashboard/settings/lang",
    path: "/dashboard/settings/lang",
    component: <Langs />,
  },
  {
    key: "/dashboard/finance/upoint",
    path: "/dashboard/finance/upoint",
    component: <Upoint />,
  },
  {
    key: "/dashboard/finance/trip_report_by_staff",
    path: "/dashboard/finance/trip_report_by_staff",
    component: <TripReportByStaff />,
  },
  {
    key: "/dashboard/finance/inventory",
    path: "/dashboard/finance/inventory",
    component: <Inventory />,
  },
  {
    key: "/dashboard/finance/staff_change",
    path: "/dashboard/finance/staff_change",
    component: <StaffChanges />,
  },
  {
    key: "/dashboard/finance/staff_warehouse",
    path: "/dashboard/finance/staff_warehouse",
    component: <StaffWarehouse />,
  },
  {
    key: "/dashboard/finance/bank_check",
    path: "/dashboard/finance/bank_check",
    component: <BankCheck />,
  },
  {
    key: "/dashboard/finance/veritech",
    path: "/dashboard/finance/veritech",
    component: <VeritechReports />,
  },
  {
    key: "/dashboard/finance/veritech_integration",
    path: "/dashboard/finance/veritech_integration",
    component: <VeritechIntegration />,
  },
  {
    key: "/dashboard/finance/veritech_integration/view/:id",
    path: "/dashboard/finance/veritech_integration/view/:id",
    component: <VeritechDateRequestDetail />,
  },
  {
    key: "/dashboard/finance/veritech_integration/view-request/:id",
    path: "/dashboard/finance/veritech_integration/view-request/:id",
    component: <VeritechRequestDetail />,
  },
  {
    key: "/dashboard/finance/upoint_month",
    path: "/dashboard/finance/upoint_month",
    component: <UpointMonth />,
  },
  {
    key: "/dashboard/finance/invoice_month",
    path: "/dashboard/finance/invoice_month",
    component: <InvoiceMonth />,
  },
  {
    key: "/dashboard/finance/invoice",
    path: "/dashboard/finance/invoice",
    component: <Invoices />,
  },
  {
    key: "/dashboard/marketing/promo",
    path: "/dashboard/marketing/promo",
    component: <Promos />,
  },
  {
    key: "/dashboard/marketing/advertisement/id",
    path: "/dashboard/marketing/advertisement/:id",
    component: <AdvertisementSingle />,
  },
  {
    key: "/dashboard/marketing/advertisement",
    path: "/dashboard/marketing/advertisement",
    component: <Advertisements />,
  },
  {
    key: "/dashboard/feedback/machine_feedback",
    path: "/dashboard/feedback/machine_feedback",
    component: <MachineFeedback />,
  },
  {
    key: "/dashboard/feedback/customer_feedback",
    path: "/dashboard/feedback/customer_feedback",
    component: <CustomerFeedback />,
  },
  {
    key: "/dashboard/feedback/machine_feedback_ref",
    path: "/dashboard/feedback/machine_feedback_ref",
    component: <MachineFeedbackRef />,
  },
  {
    key: "/dashboard/core/location",
    path: "/dashboard/core/location",
    component: <Locations />,
  },
  {
    key: "/dashboard/core/trip",
    path: "/dashboard/core/trip",
    component: <Trips />,
  },
  {
    key: "/dashboard/core/key_registration",
    path: "/dashboard/core/key_registration",
    component: <KeyRegistration />,
  },

  {
    key: "/dashboard/core/model",
    path: "/dashboard/core/model",
    component: <Models />,
  },
  {
    key: "/dashboard/core/machine",
    path: "/dashboard/core/machine",
    component: <Machines />,
  },
  // {
  //   key: "/dashboard/core/tech_request",
  //   path: "/dashboard/core/tech_request",
  //   component: <TechRequests />,
  // },
  {
    key: "/dashboard/core/machine/id",
    path: "/dashboard/core/machine/:id",
    component: <MachineSingle />,
  },
  {
    key: "/dashboard/menu/category",
    path: "/dashboard/menu/category",
    component: <Categories />,
  },
  {
    key: "/dashboard/menu/supplier",
    path: "/dashboard/menu/supplier",
    component: <Suppliers />,
  },
  {
    key: "/dashboard/menu/measurement",
    path: "/dashboard/menu/measurement",
    component: <Measurements />,
  },
  {
    key: "/dashboard/menu/product",
    path: "/dashboard/menu/product",
    component: <Products />,
  },
  {
    key: "/dashboard/menu/product/change_price/id",
    path: "/dashboard/menu/product/change_price/:id",
    component: <ProductChangePrice />,
  },
  {
    key: "/dashboard/user/staff",
    path: "/dashboard/user/staff",
    component: <Staffs />,
  },
  {
    key: "/dashboard/user/organization",
    path: "/dashboard/user/organization",
    component: <Organizations />,
  },
  {
    key: "/dashboard/rbac/permission",
    path: "/dashboard/rbac/permission",
    component: <Permissions />,
  },
  {
    key: "/dashboard/rbac/group",
    path: "/dashboard/rbac/group",
    component: <Groups />,
  },
  {
    key: "/dashboard/rbac/role",
    path: "/dashboard/rbac/role",
    component: <Roles />,
  },
  {
    key: "/dashboard/monitor/system_log",
    path: "/dashboard/monitor/system_log",
    component: <SystemLog />,
  },
  {
    key: "/dashboard/monitor/action_log",
    path: "/dashboard/monitor/action_log",
    component: <ActionLog />,
  },
  {
    key: "/dashboard/monitor/machine_log",
    path: "/dashboard/monitor/machine_log",
    component: <MachineLog />,
  },
  {
    key: "/dashboard/application_management/news",
    path: "/dashboard/application_management/news",
    component: <NewsComponent />,
  },
  {
    key: "/dashboard/application_management/promo",
    path: "/dashboard/application_management/promo",
    component: <PromoComponent />,
  },
  {
    key: "/dashboard/application_management/products",
    path: "/dashboard/application_management/products",
    component: <CustomerProducts />,
  },
  {
    key: "/dashboard/application_management/transaction",
    path: "/dashboard/application_management/transaction",
    component: <TransactionComponent />,
  },
  {
    key: "/dashboard/application_management/customers",
    path: "/dashboard/application_management/customers",
    component: <Customers />,
  },
  {
    key: "/dashboard/application_management/point_management",
    path: "/dashboard/application_management/point_management",
    component: <PointManagement />,
  },
  {
    key: "/dashboard/application_management/coupon",
    path: "/dashboard/application_management/coupon",
    component: <Coupon />,
  },

  {
    key: "/dashboard/application_settings/term_of_service",
    path: "/dashboard/application_settings/term_of_service",
    component: <TermOfService />,
  },
  {
    key: "/dashboard/application_settings/wheel",
    path: "/dashboard/application_settings/wheel",
    component: <WheelComponent />,
  },
  {
    key: "/dashboard/application_settings/survey",
    path: "/dashboard/application_settings/survey",
    component: <SurveyComponent />,
  },
  {
    key: "not_found",
    path: "*",
    component: <NotFound />,
  },
];
export default dashboardRoutes;

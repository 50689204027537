import { ProSettings } from "@ant-design/pro-layout";
import { Admin } from "services/admin/auth/types";
import { Lang } from "services/guest/reference/types";

export interface Settings extends Partial<ProSettings> {
  listen_socket: boolean;
}
export interface DataType {
  authorized: boolean;
  auth?: Admin;
  settings: Settings;
  refrences: Refrences;
  notificationsCount: number;
  langs: Lang[];
}

export enum Action {
  SIGN_OUT = "SIGN_OUT",
  SIGN_IN = "SIGN_IN",
  INCREASE_NOTI_COUNT = "INCREASE_NOTI_COUNT",
  SET_SETTINGS = "SET_SETTINGS",
  SET_LANGS = "SET_LANGS",
  SET_REFRENCES = "SET_REFRENCES",
}

export type ReducerType = (state: DataType, action: Actions) => DataType;

export type Actions =
  | [Action.SIGN_OUT]
  | [Action.INCREASE_NOTI_COUNT]
  | [Action.SIGN_IN, Admin]
  | [Action.SET_LANGS, Lang[]]
  | [Action.SET_SETTINGS, Settings]
  | [Action.SET_REFRENCES, Refrences];

export type Refreshs = {
  info: () => void;
};
export type Type = [DataType, (action: Actions) => void, Refreshs];

export interface Refrences {
  loading: boolean;
}

export interface CabinClass {
  name: string;
  value: string;
}

export interface PassengerType {
  name: string;
  value: string;
}

import { StoreProvider } from "contexts";
import Routes from "routes";
import NavigationScroll from "routes/components/navigation-scroll";
import {} from "@ant-design/pro-form";
import { ConfigProvider, createIntl } from "@ant-design/pro-provider";
import { ConfigProvider as AntdConfigProvider } from "antd";
import mnMN from "antd/lib/locale/mn_MN";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { proMNLocale } from "./config";
import "./global.less";

const container: any = document.getElementById("root");
const root = createRoot(container);

root.render(
  <AntdConfigProvider locale={mnMN}>
    <ConfigProvider
      value={{
        intl: createIntl("en", proMNLocale),
        valueTypeMap: proMNLocale,
      }}
    >
      <BrowserRouter>
        <StoreProvider>
          <DndProvider backend={HTML5Backend}>
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </DndProvider>
        </StoreProvider>
      </BrowserRouter>
    </ConfigProvider>
  </AntdConfigProvider>
);

import { PageLoading } from "@ant-design/pro-layout";
import { ErrorBoundary } from "@ant-design/pro-utils";
import { Button, notification } from "antd";
import { useStore } from "contexts";
import { Action } from "contexts/types";
import { useSocket } from "hooks/useSocket";
import { FC, lazy, Suspense } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import dashboardRoutes from "routes/dashboard";
import auhtRoutes from "./auth";
import { IRoute } from "./types";

const AuthLayout = lazy(() => import("layouts/auth"));
const DashboardLayout = lazy(() => import("layouts/dashboard"));
const NotAuthorized = lazy(() => import("pages/exceptions/NotAuthorized"));
const NotFound = lazy(() => import("pages/exceptions/NotFound"));

const MainRoutes: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [{ authorized }, dispatch] = useStore();

  useSocket({
    onMessage: (msg) => {
      if (msg.type === "ORDER") {
        dispatch([Action.INCREASE_NOTI_COUNT]);
        let desk: any = msg.content;
        notification.open({
          message: <>{`✅ ${desk.order_uid} дугаартай шинэ захиалга ирлээ.`}</>,
          description: (
            <>
              {!(
                location.pathname === "/dashboard/order/deliver" ||
                location.pathname === "/dashboard/report/sell"
              ) && (
                <Button
                  size="small"
                  onClick={() => {
                    navigate("/dashboard/order/deliver");
                  }}
                >
                  Нээх
                </Button>
              )}
            </>
          ),
          duration: 0,
        });
      }
    },
  });

  const routes: IRoute[] = [
    {
      path: "/auth",
      key: "auth",
      component: <AuthLayout />,
      children: auhtRoutes,
    },
    {
      path: "/403",
      key: "not_authorized",
      component: <NotAuthorized />,
    },
    {
      key: "not_found",
      path: "*",
      component: <NotFound />,
    },
  ];

  if (authorized) {
    routes.push({
      path: "/dashboard",
      key: "dashboard",
      component: <DashboardLayout />,
      children: dashboardRoutes,
    });
  }

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route key="root" path="/" element={authorized ? <Navigate to={"/dashboard"} /> : <Navigate to={"/auth/login"} />} />
          {routes.map((route) =>
            !route.children ? (
              <Route
                key={route.key}
                path={route.path}
                element={
                  <ErrorBoundary>
                    <Suspense fallback={<PageLoading />}>{route.component}</Suspense>
                  </ErrorBoundary>
                }
              />
            ) : (
              <Route
                key={route.key}
                path={route.path}
                element={
                  <ErrorBoundary>
                    <Suspense fallback={<PageLoading />}>{route.component}</Suspense>
                  </ErrorBoundary>
                }>
                {route.children.map((item) => (
                  <Route
                    key={item.key}
                    path={item.path}
                    element={
                      <ErrorBoundary>
                        <Suspense fallback={<PageLoading />}>{item.component}</Suspense>
                      </ErrorBoundary>
                    }
                  />
                ))}
              </Route>
            )
          )}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default MainRoutes;

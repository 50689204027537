import { useDebounceFn } from "ahooks";
import { message } from "antd";
import { useStore } from "contexts";
import { Action } from "contexts/types";
import { useEffect, useState } from "react";

const httpURL = import.meta.env.VITE_API_URL;
let wsURL = "";
if (httpURL?.includes("https")) {
  wsURL = httpURL?.replace("https", "wss");
} else {
  wsURL = httpURL?.replace("http", "ws");
}
const webSocketURL = wsURL + "socket/connect";

export interface SocketMessage {
  type: string;
  content: any;
}
interface SocketProps {
  onMessage: (data: SocketMessage) => void;
}

let websocket: WebSocket | null = null;

export const useSocket = (props: SocketProps) => {
  const [ws, setWs] = useState<WebSocket>();
  const [audio] = useState(new Audio("/assets/bell.mp3"));

  const [{ settings }, dispatch] = useStore();
  const { listen_socket } = settings;
  const { run: runOnclose } = useDebounceFn(
    () => {
      if (websocket) message.error("Мэдэгдэл сонсох урсгал салсан байна.");
      websocket?.close();
    },
    { wait: 0 }
  );
  const { run: reInitSocket } = useDebounceFn(
    () => {
      websocket = new WebSocket(webSocketURL);
      websocket.addEventListener("close", () => {
        console.log("socket not connected");
        runOnclose();
        if (listen_socket) {
          dispatch([
            Action.SET_SETTINGS,
            { ...settings, listen_socket: false },
          ]);
        }
      });
      websocket.addEventListener("onerror", () => {
        console.log("socket not connected");
        runOnclose();
        if (listen_socket) {
          dispatch([
            Action.SET_SETTINGS,
            { ...settings, listen_socket: false },
          ]);
        }
      });
      websocket.addEventListener("message", (ev) => {
        let data = JSON.parse(ev.data) as SocketMessage;
        if (data.type === "PING" || data.type === "CONTENT") {
          let dataToSend = JSON.stringify({
            type: "PONG",
            content: "this is data",
          });
          websocket?.send(dataToSend);
        } else {
          audio?.play();
          if (listen_socket) props.onMessage(data);
        }
      });
      setWs(websocket);
    },
    {
      wait: 0,
    }
  );

  useEffect(() => {
    if (listen_socket) {
      reInitSocket();
    } else {
      runOnclose();
    }
  }, [listen_socket, reInitSocket, runOnclose]);

  return ws;
};
